.dustTransformsHistory {
  border-radius: 16px;
    
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  
    h2 {
      font: 25px "Inter-SemiBold";
      color: var(--color-black);
    }
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: var(--table-header-bg-color);
    padding: 20px 35px;
        
        
    .buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 23px;
      margin-left: auto;
  
      svg {
        cursor: pointer;
      }
    }
    
    .selectItem {
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--color-black);
    }
  }
    
  .historyTableWrapper {
    width: 100%;
    border-spacing: 0 15px;
    border-collapse: separate;
    background-color: var(--table-bg-color);
    padding: 0 10px;
    height: 100%;
    min-height: 345px;
    max-height: 345px;
    overflow: auto;
    border-radius: 0 0 16px 16px;

    .emptyWrapper {
      height: calc(100% - 45px);

      [class*="empty"] {
        height: 100%;
      }
    }
    
    .table {
      .tdWrapper {
        font: 12px "Inter-Regular";
        color: var(--color-black);
      }
    }

    .loadingWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 45px) !important;
      margin: auto;
    }
    
    table {
      table-layout: fixed;
      width: 100%;
    }
    
    thead {
      position: sticky;
      top: 0;
      z-index: 99;
      text-align: left;
      border-spacing: 0 15px;
      border-collapse: separate;
    }
      
    tr {
      font: 11px "Inter-Regular";
      color: #737573;
      text-align: left;
      border-bottom: 1px solid #D9D9D9;
      height: 42px;

      th:first-child {
        border-top-left-radius: 8px; 
        border-bottom-left-radius: 8px;
        padding-left: 20px;
      }
  
      th:last-child {
        border-top-right-radius: 8px; 
        border-bottom-right-radius: 8px;
      }
      
      td:first-child {
        padding-left: 20px;
      }
    }
      
    th {
      height: 26px;
      cursor: pointer;
      font: 11px "Inter-Regular";
      color: var(--table-column-text-color) !important;
      text-align: left;
      vertical-align: middle;
      
      span {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    td {
      border-bottom: 1px solid var(--table-border-color);
      font: 13px "Inter-Regular";
      color: var(--color-black);
      vertical-align: middle;
      box-sizing: border-box;
    
      & > div {
        font: 11px "Inter-Regular";
        white-space: nowrap;
        margin-right: 15px;
      }        
    }
    
    .historyItem {
      cursor: pointer;
    }
    
    .thWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;
  
      &.activeColumn {
        color: var(--color-main);
  
        svg path {
          stroke: var(--color-main);
        }
      }
    
      &:hover {
        color: var(--table-column-text-color-hover);
  
        svg path {
          stroke: var(--table-column-text-color-hover);
        }
      }
    }
  }
    
  .loadingItemWrapper {
    margin-top: 15px;
  
    .loader {
      height: 10px;
    }
  }
}
  
.checkbox {
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
  background: #D9D9D9;
  border-radius: 4px;
  transition: border-color 0.3s;
  border: 1px solid #D9D9D9;
  appearance: none;
  margin-right: 10px;
    
  &:hover {
    border: 1px solid var(--color-main);
  }
  
  &:focus {
    border: 1px solid var(--color-main);
  }
  
  &:checked {
    background: var(--color-main);
  }
  
  &:checked::before {
    content: "✓";
    position: absolute;
    top: 45%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: var(--white); 
    font: 12px "Inter-SemiBold";
  }
}

html[data-theme='dark'] {
  .dustTransformsHistory {
    .header {
      border: none;
      background-color: #1D1D1D;
      height: unset;
    }

    table {
      background-color: #1D1D1D;
      border-collapse: unset;

      thead {
        tr {
          th {
            .checkbox {
              background: #D9D9D9;

              &:checked::before {
                color: var(--color-black-1e); 
              }
            }
          }
        }
      }
    }

    .positionCardWrapper {
      .positionCard {
        background: #343434;

        .box {
          .leverage {
            background: #737573;
          }
          
          .averagePrice {
            svg path {
              stroke: white;
            }
          }
        }
      }
    }
  }
}