.bonusParams {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .filterBtn {
    cursor: pointer;
    transition: transform 0.2s ease;

    &:active {
      transform: scale(0.9);
    }

    &:hover {
      path {
        stroke: var(--color-orange);
      }
    }

    path {
      stroke: var(--color-black);
    }
  }

  h1 {
    font: 18px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);
  }
}

.selectItem {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-black);

  &:hover {
    color: var(--white);
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.selectCurrentItem {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-black);

  img {
    width: 20px;
    height: 20px;
  }
}

.drawer {
  [class*="MuiPaper-root"] {
    background-color: var(--input-bg-color);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 35px 25px;
    margin: 0 15px;
  }
  
  .filterWrapper {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 20px;
    
    &::-webkit-scrollbar {
      display: none;
    }
  
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
  
      .closeBtn {
        &::before {
          background: var(--color-black);
        } 
          
        &::after {
          background: var(--color-black);
        }
      }
  
      h3 {
        font: 18px "Inter-SemiBold", Arial, sans-serif;
        color: var(--color-black);
      }
    }
  
    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .sortItemsContainer {
        max-height: 116px;
      }
  
      .typesItemsContainer {
        max-height: 185px;
      }
  
      .selectItem {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--color-black);
      }
  
      [class*="data-picker"] {
        max-width: 100%;
      }
    }
  }
  
  [class*="root-MuiModal-backdrop"] {
    backdrop-filter: blur(3px);
  }
}