$border-color: var(--input-border-color);

@mixin unset-styles {
  background: unset;
  border-radius: unset;
  padding: 0;
}

.leverageAdaptive {
  margin-top: 90px;
  padding: 0 20px 20px;

  .search {
    background-color: transparent;
    border-radius: 8px;
    margin-bottom: 20px;
    visibility: hidden;
    opacity: 0;
    margin-top: -40px;
    transition: all 0.3s ease;
    z-index: 5;

    input {
      background: transparent;
      font: 11px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
      border: 1px solid $border-color;

      &:focus {
        border-color: var(--color-main);
      }
    }

    &.show {
      visibility: visible;
      opacity: 1;
      margin-top: 20px;
    }
  }

  .divider {
    margin: 20px 0;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font: 18px "Inter-SemiBold", Arial, sans-serif;
      color: var(--color-black);
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 15px;

      svg {
        display: flex;
        width: 20px;
        height: 20px;

        &:hover {
          cursor: pointer;
          
          path {
            stroke: var(--color-orange);
          }
        }

        path {
          stroke: var(--color-black);
        }
      }
    }
  }

  .leveragesTable {
    display: flex;
    flex-direction: column;
    gap: 10px;

    [class*='select-skeleton'] {
      margin-top: 0 !important;
    }
  }

  .emptyWrapper {
    display: flex;
    height: calc(100vh - 300px);
  }
}

.drawer {
  [class*='MuiPaper-elevation'] {
    max-height: 70vh;
  }

  [class*="MuiPaper-root"] {
    padding: 35px 25px 10px !important;
  }


  [class*="root-MuiModal-backdrop"] {
    backdrop-filter: blur(3px);
  }


  [class*="MuiPaper-root"] {
    background-color: var(--input-bg-color);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 35px 25px;
    margin: 0 15px;
  }

  .filterWrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    scrollbar-width: none; /* Firefox */
    margin-bottom: 20px;
  
    &::-webkit-scrollbar {
      display: none; /* Safari и Chrome */
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      .closeBtn {
        &::before {
          background: var(--color-black);
        } 
        
        &::after {
          background: var(--color-black);
        }
      }

      h3 {
        font: 18px "Inter-SemiBold", Arial, sans-serif;
        color: var(--color-black);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (width <=976px) {
        [class*="slider-container"] {
          margin: 20px 7px 0;
        } 
      }

      .selectItem {
        padding: 10px;
      }
    }

    .contentFilter {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;

      .selectItem {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px;
        
        img {
          width: 20px;
          height: 20px;}
      }

    }

    .contentSettings {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;


      .leverageSettings {
        display: flex;
        flex-direction: column;
        gap: 20px;
        box-sizing: border-box;
  
        h1 {
          font: 18px "Inter-SemiBold", Arial, sans-serif;
          color: var(--color-black);
        }
  
        p {
          color: var(--color-black);
          font: 14px "Inter-Mediun", Arial, sans-serif;
          line-height: 1.2;
          margin-top: -15px;
        }
  
        .leverageItemWrapper {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-gap: 10px;
  
          .leverageItem {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 47px;
            border-radius: 8px;
            background-color: var(--input-bg-color);
            font: 13px "Inter-Regular", Arial, sans-serif;
            color: var(--color-black);
            text-align: center;
            cursor: pointer;
            transition: all 0.2s ease;
            border: 1px solid var(--input-border-color);
  
            &:hover {
              background-color: var(--white);
              border-color: var(--color-main);
            }
  
            &:active {
              transform: scale(0.98);
            }
  
            &.selectedItem {
              background-color: var(--white);
              border-color: var(--color-main);
            }
          }
        }
      }

      [class*="auto-leverages"] {
        @include unset-styles;
      }    
      
      [class*="auto-changes-leverages"] {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include unset-styles;
      }      
      
      [class*="auto-leverages-calculator"] {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include unset-styles;
      }  
      
      [class*="common-settings"] {
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        @include unset-styles;
      }
    }
  }

  .calculatorActions {
    display: flex;
    gap: 10px;
  }
}

html[data-theme='dark'] {
  .leverageItem {
    &:hover {
      background-color: var(--color-black-1e) !important;
    }

    &.selectedItem {
      background-color: var(--color-black-1e) !important;
    }
  } 
}