.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
  min-width: 370px;

  @media (width <= 576px) {
    min-width: unset;
  } 

  h3 {
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--white);
  }

  p {
    font: 15px "Inter-Regular", Arial, sans-serif;
    color: var(--white);
    line-height: 1.5;
  }

  img, svg {
    width: 100px;
  }
}