.api-keys-slide {
  display: flex;
  gap: 10px;

  @media (width <= 1120px) {
    flex-direction: column;
   
  }

  .title {
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);
  }

  & > div {
    background-color: var(--table-bg-color);
    border-radius: 8px;
    padding: 30px;

    h5 {
      font: 14px 'Inter-Medium', Arial, sans-serif;
      color: var(--color-black);
      margin-bottom: 7px;
    }
  }
  
  .apikey-list-wrapper{
    position: relative;
    width: 58%;
    min-width: 780px;
    min-height: 400px;

    @media (width <= 1120px) {
      width: 100%;
      min-width: 100%;
      min-height: 100%;
    }

    @media (width <= 976px) {
      background: transparent !important;
      padding: 0;
    }

    table {
      width: 100%;
      border-spacing: 0 15px;
      border-collapse: separate;

      thead {
        position: sticky;
        top: 0;
        z-index: 99;

        tr {
          th {
            font: 11px 'Inter-Regular', Arial, sans-serif;
            color: (--input-comment-color);
            text-align: left;
            border-bottom: 1px solid var(--input-border-color);
            padding-bottom: 14px;
            width: 100px;
          }
        }
      }

      tbody {
        tr {
          td {
            font: 13px 'Inter-Regular', Arial, sans-serif;
            color: var(--color-black);
            vertical-align: top;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 10px;
            max-width: 200px;

            &.name {
              width: 120px;
              max-width: 200px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            &.permissions {
              ul {
                list-style: inside;

                li {
                  font: 13px "Inter-Light", Arial, sans-serif;
                  color: var(--color-black);
                }
              }
            }

            &.actions {
              display: flex;
              justify-content: center;
             

              button {
                margin-left: 13px;
                cursor: pointer;

                &:first-child {
                  margin-left: 0;
                }

                &:hover {
                  svg path {
                    stroke: #00C37C;
                  }
                }
              }
            }
          }

          .account-tr {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 150px;
            max-width: 200px;

            img {
              height: 20px;
            }

          }

          .apikey-tr {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 150px;
            max-width: 200px;
          }

          .name-tr {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 150px;
            max-width: 200px;
          }
        }
      }
    }
  }

  .apikey-list-container {
    position: relative;
    width: 100%;
    height: 100%;

    @media (width <= 1120px) {
      flex-direction: column;
      width: 100%;
    }

    @media (width <= 976px) {
      .title {
        display: none;
      }
    }

    .apikey-table-wrapper {
      overflow: auto;
    }

    .api-keys-state-view {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -60%);
      width: 100%;
      text-align: center;
      font: 16px "Inter-Regular", Arial, sans-serif;
      color: #737573;
    }
  }

  .apikey-slide-passport {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    width: 100%;
    justify-content: center;
    transform: translate(-50%, -50%);
  }
}

.ip-address-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;

  .ip-address-list-empty {
    font: 12px "Inter-Regular", Arial, sans-serif;
    color: #5A5A5A;
  }

  li {
    display: flex;
    align-items: center;

    input {
      position: relative;
      margin-right: 10px;
      cursor: pointer;
      width: 15px;
      height: 15px;
      appearance: none;
      background: #D9D9D9;
      border-radius: 4px;
      transition: border-color 0.3s;
      
      
      &:checked {
        background: var(--color-main);
      }

      &:checked::before {
        content: "✓"; /* добавляем символ X */
        position: absolute;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%);
        color: var(--white); /* цвет символа X */
        font: 12px "Inter-SemiBold", Arial, sans-serif;
      
      }
    
    }

    label {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
    }

    span {
      margin-left: auto;
      margin-right: 10px;
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
    }

    button {
      cursor: pointer;

      &:hover {
        svg path {
          stroke: var(--color-red);
        }
      }

      &:focus {
        svg path {
          stroke: var(--color-red);
        }
      }
    }
  }
}
 
.validation-error{
  margin-top: 15px;
  display: flex;
}

.create-apikey-container {
  width: 42%;
  max-width: 356px;
  box-sizing: border-box;
  height: max-content;
  
  h4 {
    max-width: 70%;
    white-space: wrap;
  }

  &.modal-container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0 20px;

    .delimiter {
      background-color: #D9D9D9;
    }
  }

  @media (width <= 1120px) {
    width: 100%;
    max-width: 100%;
    min-width: 480px;
  }

  @media (width <= 976px) {
    min-width: 100%;
  }

  & > div {
    margin-top: 23px;
  }

  .apikey-name {     
    .input-text {
      display: flex;
      align-items: center;
      max-height: 42px;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid var(--input-border-color);
      box-sizing: border-box;
      outline: none;

      input {
        outline: none;
        background: none;
        border-radius: 8px;
        border: none;
        padding: 12px;
      }
    }
  }

  .selected-item {
    background: transparent;
  }

  .select-wallet-title {
    margin-bottom: 5px;
    margin-top: 0;
  }

  .select-wallet-modal {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    padding: 7px 12px;
    margin: 0;
    
    img {
      // width: 20px;
      height: 20px;
      border-radius: 50%;
      filter: grayscale(100%);
    }
   
    svg g path {
      fill: #888;
    }

    .short-icon {
      display: flex;
      margin-left: auto;
    }  

    .short-name {
      color: #737373;
    }  
  }

  .select-wallet {
    &.disabled-select {
      filter: grayscale(1);

      .instrument {
        cursor: default;
      }

      .disabled-select-img {
        margin-left: auto;
        border-radius: 0;
      }

      .selected-item svg {
        display: none;
      }
    }

    .instruments {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .instrument, 
    .network {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;

      img {
        width: 20px;
        height: 20px;
      }

    }
  }

  .apikey-permissions {
    .toogler {
      margin-top: 8px;
    } 
  }


  .access-type, .ip-type {
    .advice-paragraph {
      margin-bottom: 10px;

      p {
        font: 10px "Inter-Regular", Arial, sans-serif;
        color: gray;
        margin-left: 6px;
      }

      svg {
        width: 20px;
        height: auto;
      }
    }

    .input-radio {
      margin-top: 8px;
    }
  }

  .ip-type {
    .input-radio {
      justify-content: space-between;
    }
   
  }

  .ip-type .delimiter {
    margin: 20px 0;
  }

  .new-ip {
    display: flex;
    flex-direction: column;

    & .new-ip-inputs {
      display: flex;
      gap: 5px;
    }

    & .delimiter {
      margin-bottom: 0;
    }

    & .btn{
      margin-top: 20px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .btn {
      width: 100%;
    }
  }

  .remark {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      font: 11px 'Inter-Light', Arial, sans-serif;
      color: #737573;
    }
  }
}

.secret-key-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px;

  .secret-key-input {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--input-border-color);
    border-radius: 8px;

    p {
      font: 13px "Inter-Regular", Arial, sans-serif;
    }
  }
}