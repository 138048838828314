.assetContainer {  
  background-color: var(--card-bg-color);
  border-radius: 16px;
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;

    h2 {
      display: flex;
      align-items: center;
      gap: 10px;
      font: 20px "Inter-Medium", Arial, sans-serif;
      color: var(--color-black);
    }

    .subAccount {
      display: flex;
      align-items: center;
      gap: 5px;

      .account {
        font: 13px "Inter-Regular", Arial, sans-serif;
        color: var(--color-black);
        margin-right: 5px;
      }

      img {
        height: 15px;
      }
    }
  }

  .divider {
    margin: 20px 0;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    span {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
      
    .replenishmentIcon path {
      stroke: var(--color-main);
    } 
    
    .withdrawalIcon path {
      stroke: var(--color-red);
    }

    .transferIcon path {
      stroke: #9747FF;
    }
  }
}

