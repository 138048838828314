.subAccountAdaptiveWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  @media (width <= 976px) {
    margin-top: 90px;
    padding: 0 20px;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    gap: 35px;
    flex: 1 1;
    border-radius: 16px;
    margin-bottom: 20px;
  }  

  .content {
    .header {
      min-height: 42px;
      
      h1 {
        font: 18px "Inter-SemiBold", Arial, sans-serif;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .subAccountsWrapper {
      margin-bottom: 20px;
      
      [class*="mantine-Carousel-indicator"] {
        &[data-active] {
          width: 50px;
          background-color: #FF8605 !important;
        }

        button {
          background: var(--input-border-color);
          opacity: 1 !important;
          width: 16px;
          height: 6px;
          transition: width 250ms ease;
        }
      }  
      
      [class*="mantine-Carousel-indicators"] {
        inset-inline-end: unset;
      }
    }
  }
}
