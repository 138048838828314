.settingsPage {
  @media (width <= 976px) {
    margin-top: 90px;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .title {
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);
  }

  .subtitle {
    font: 14px "Inter-Regular", Arial, sans-serif;
    color: var(--color-black);
    line-height: 2;

    @media (width <= 500px) {
      line-height: 1.2;
      margin-top: 10px;
      font-size: 12px;
      max-width: 250px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;

  .section {
    background: var(--table-bg-color);
    border-radius: 16px;
    min-width: 976;
  
    @media (width <= 976px) {
      min-width: 100%;
    }
  
    h2 {
      font: 14px "Inter-Medium", Arial, sans-serif;
      color: var(--color-black);
      padding: 20px 50px;
      letter-spacing: 3px;
  
      @media (width <= 576px) {
        padding: 20px;
      }
    }
  
    .block {
      margin: 20px 80px 20px 50px;
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      @media (width <= 576px) {
        margin: 20px;
      }
  
      .selectItem {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--color-black);
      }
  
      .inputSelect {
        max-width: 300px;
  
        @media (width <= 576px) {
          max-width: 100%;
        }
      }
  
      .subAccounts {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
  
        @media (width <= 576px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
        }    
        
        @media (width <= 410px) {
          grid-template-columns: repeat(1, 1fr);
        }
        
        .subAccount {
          filter: grayscale(1);
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 10px;
          border: 1px solid #BDBDBD;
          border-radius: 8px;
          transition: all 0.3s ease;
          color: #BDBDBD;
          white-space: nowrap;
          min-width: 155px;
  
          &:active {
            transform: scale(0.98);
          }
  
          &:hover {
            filter: unset;
            border: 1px solid var(--color-black);
            color: var(--color-black);
          }
  
          &.select {
            filter: unset;
            border: 1px solid var(--color-main);
            color: var(--color-black);
          }
  
          img {
            width: 20px;
            height: 20px;
          }
  
          span {
            font: 13px "Inter-Regular", Arial, sans-serif;
          }
        }
      }
  
      .hintInfo {
        font: 12px "Inter-SemiBold", Arial, sans-serif;
        padding-left: 20px;
      }
    }
  
    .actions {
      display: flex;
      gap: 10px;
      max-width: 350px;
      margin-left: 50px;
      margin-bottom: 20px;
  
      @media (width <= 576px) {
        margin: 20px;
        max-width: 100%;
      }
    }
  
    .multiassetMode {
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .multiassetList {
        padding-left: 40px;
        list-style: disc;
  
        li {
          font: 12px "Inter-Regular", Arial, sans-serif;
          color: var(--toogle-label-color);
        }
      }
    }
  }
}

.tabsContainer {
  margin-top: 30px;
  
  .tabNavigation {
    border-bottom: 2px solid var(--divider-color);

    [data-active*='true'] {
      &::after {
        bottom: -4px;
      }
    }

    &::before {
      display: none;
    }
    
    --tab-border-color: var(--divider-color);
  
    .tabItem {
      &:hover {
        border-color: transparent;

        --tab-bg: unset;
      
        span {
          color: var(--color-main);
        }
      }
    }
  }

  .emptyWrapper {
    height: 68vh;
  }
}

@media (width <= 976px) {
  .hintWrapper {
    div {
      align-items: flex-start !important;
    }
  }
}