.filterBox {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .selectContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 360px;

    .selectGroup {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .selectItem {
        .filterItem {
          display: flex;
          padding: 10px;
          font: 12px "Inter-Regular";
        }
      }
    }
  }
}

.searchInput {
  display: flex;
  flex-direction: column;
  gap: 7px;

  span {
    font: 14px "Inter-Medium", Arial, sans-serif;
    color: var(--color-black);
  }

  input {
    background-color: var(--input-bg-color) !important;
    border: 1px solid var(--input-border-color) !important;
    padding: 12px 10px !important; 
    border-radius: 8px !important;
    font: 13px "Inter-Regular", Arial, sans-serif !important;
    min-width: 200px;
    padding-right: 36px !important;
  }

  svg path {
    stroke: var(--input-border-color);
  }

  button {
    display: flex;
  }
}

.walletTableInfo {
  display: flex;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  text-align: center;

  span {
    font: 16px "Inter-Medium", Arial, sans-serif;;
    color: gray;
  }
}

.walletsAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.walletTable {
  width: 100%;
  border-spacing: 0 15px;
  border-collapse: separate;
  table-layout: fixed;
  margin-bottom: 20px;
  
  thead {
    position: sticky;
    top: 0;
    z-index: 99;
    text-align: left;
    border-spacing: 0 15px;
    border-collapse: separate;
  }

  th {
    cursor: pointer;
    font: 11px "Inter-Regular", Arial, sans-serif;;
    color: var(--color-black);
    text-align: left;
    border-bottom: 1px solid var(--input-border-color);
    padding-bottom: 14px;

    &:hover {
      color: var(--color-main);
      
      .thWrapper {
        svg path {
          stroke: var(--color-main);
        }
      }
    }

    .thWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      
      svg path {
        stroke: var(--color-black);
      }
    }
  }

  td {
    // width: 100px;
    font: 13px "Inter-Regular", Arial, sans-serif;
    color: var(--color-black);
    vertical-align: middle;
    box-sizing: border-box;
    padding-right: 5px;
    white-space: nowrap;

    div {
      display: flex;
      align-items: center;
      gap: 5px;

      img {
        height: 20px;
      }
    }
  }

  .walletAddress {
    cursor: pointer;
    width: 100px !important;
    max-width: 100px;
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis; 
    padding-right: 50px;
  }

  .actions {
    button {
      cursor: pointer;
      margin-right: 20px;

      svg path {
        stroke: var(--color-black);
      }

      &:hover {
        svg path {
          stroke: var(--color-red);
        }
      }
    }
  }
}