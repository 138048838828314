.transfers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 35px;
  min-height: 600px;
  min-width: 350px;
  background-color: var(--transfer-bg-color);
  border-radius: 16px;
  align-self: flex-start;

  @media (width <= 976px) {
    width: 100%;
    min-width: unset;

    [class*="input-form"] {
      margin-top: 5px !important;
    }
  }
  
  .selectItem {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
  
    img {
      width: 20px;
      height: 20px;
    }
  }
  
  h2 {
    color: var(--color-black);
    font: 25px "Inter-SemiBold", Arial, sans-serif;
    margin-bottom: 10px;
  }
  
  .selectInstrumentItem {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
  
    img {
      height: 20px;
    }
  
    .symbol {
      color: var(--color-black);
      font: 12px "Inter-Regular", Arial, sans-serif;
    }
  }
  
  .inputSelect {
    margin: 10px 0;
  }
  
  .coup {
    display: flex;
    align-items: center;
    margin: 5px 0;

    @media (width <= 976px) {
      margin-bottom: -10px;
    }
      
    span {
      display: flex;
      flex: 1;
      height: 1px;
      background: var(--divider-color);
    }
      
    button {
      display: flex;
      padding: 12px;
      background-color: var(--color-orange);
      border-radius: 8px;
      margin: 0 20px;
      transition: transform 0.2s ease;

      &:active {
        transform: scale(0.95);
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-main);
        }
      }
    }
  }
    
  .divisorWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 5px 0;
  }
  
  .transferInfo {
    display: flex;
    gap: 5px;
    max-width: 250px;
  
    p {
      color: #737573;
      font: 10px "Inter-Regular", Arial, sans-serif;
    }
  
    svg {
      display: flex;
      flex: 1 1 40px;
    }

    a {
      color: #737573;
      border-bottom: 1px solid #737573;

      @media (width <= 976px) {
        pointer-events: none;
      }

      &:hover {
        color: var(--color-black);
        border-bottom: 1px solid var(--color-black);
      }
    }
  }
}

html[data-theme='dark'] {
  .transfers {
    background: linear-gradient(227deg, #131313 0%, #2B2B2B 100%);
  }
}