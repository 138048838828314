@mixin badge-style($bg-color: #000) {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font: 12px "Inter-Regular", Arial, sans-serif;
  color: var(--white) !important;
  width: 20px;
  height: 20px;
  background: $bg-color;
}


.spot {
  @include badge-style(var(--color-orange));
}

.futures {
  @include badge-style(var(--color-main));
}

.unified {
  @include badge-style(var(--color-blue));
}