.auth-input-container {
  position: relative;

  &.isDisabled  {
    display: none;
  }

  input.auth-input {
    position: relative;
    width: 100%;
    height: 40px;
    border: 1px solid var(--input-border-color);
    background-color: transparent;
    padding: 0 0 0 25px;
    border-radius: 10px;
    outline: none;
    font-size: 13px;
    margin-left: -15px;
    z-index: 1;

    @media (width <= 976px) {
      color: var(--white);
    }
  }

  label {
    display: inline-flex;
    color: var(--color-black);
    font: 14px "Inter-Medium", Arial, sans-serif;
    margin-bottom: 10px;
  }

  .input-action {
    position: absolute;
    right: 16px;
    bottom: 12px;
    display: flex;
    cursor: pointer;
    color: var(--color-orange);
    font: 12px "Inter-Regular", Arial, sans-serif;
    z-index: 2;

    &:hover {
      text-decoration: underline;
    }
  }

  & > p {
    font: 13px 'Inter-Light', Arial, sans-serif;
    letter-spacing: 1.56px;
    margin: 5px 0 10px 7px;
  }

  &_validation-error {
    input {
      border-color: var(--color-red) !important;
      color: var(--color-red) !important;
    }

    p {
      color: var(--color-red) !important;
    }
  }

  &_validation-success {
    input {
      border-color: var(--color-main) !important;
    }
  }

  button {
    position: absolute;
  }

  .icon {
    position: absolute;

    &.discord-icon {
      top: 10px;
      right: 15px;

      button {
        position: relative;

        svg {
          &:hover {
            animation-duration: 1s;
            animation-name: rotate;
          }
        }
      }
    }
  }

  &_phone {
    label {
      color: var(--color-black);
    }

    .input-container {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;

      .country-list-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid #E8E8E8;
        background-color: #F7F6F7;
        padding: 0 10px;
        height: 40px;
        border-radius: 10px;
        outline: none;
        font-size: 13px;
        z-index: 2;

        @media (width <= 976px) {
          border: 1px solid var(--input-border-color);
          background-color: var(--color-black-13);
         
          .phone-format {
            color: var(--white) !important;
          }
        }
  
        &_active {
          background: var(--color-main);

          svg {
            transform: rotate(180deg);

            & path {
              stroke: var(--color-white);

              @media (width <= 976px) {
                stroke: var(--white);
              }
            }
          }

          .phone-format {
            color: var(--white) !important;
          }
        }
  
        .country-flag {
          width: 21px;
          height: 15px;
          background-image: url('../../../../shared/assets/images/auth/flags.png');
          background-repeat: no-repeat;
          border: 1px solid black;
          box-sizing: border-box;
        }
  
        .phone-format {
          font: 13px 'Inter-Regular', Arial, sans-serif;
          letter-spacing: 2.6px;
          color: black;
          margin-left: 6px;
        }
        
        svg {
          min-width: 16px;
          min-height: 16px;
          margin-left: 2px;
        }
      }
    }

    .select-container {
      border: 1px solid #E8E8E8;
      border-radius: 10px;
      margin-top: 5px;
      z-index: 20;
      position: absolute;
      width: 100%;

      @media (width <= 976px) {
        border-color: var(--input-border-color);
      }

      .search-form {
        background-color: #F7F6F7;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        border-bottom: 1px solid #E8E8E8;

        @media (width <= 976px) {
          border-color: var(--input-border-color);
        }

        input {
          border: none;
          padding: 13px 10px;
          width: 100%;
          box-sizing: border-box;
          outline: none;
          font-size: 16px;
          background-color: #F7F6F7;

          @media (width <= 976px) {
            background-color: var(--color-black-13);
            color: var(--white);
          }
        }
      }

      .list-country {
        height: 240px;
        max-height: 310px;
        border-radius: 0 0 8px 8px;
        overflow-y: auto;
        background-color: #F7F6F7;

        @media (width <= 976px) {
          background-color: var(--color-black-13);
        }

        @media (width >= 1200px) {
          .list-country { 
            height: 170px;
          }
        }

        .select-country {
          padding: 12px 15px;
          margin: 0 5px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
          border-bottom: 1px solid #E8E8E8;
          transition: all 0.3s ease;
          color: var(--color-black-13);

          @media (width <= 976px) {
            background-color: var(--color-black-13);
            border: none;
            color: var(--white);
          }
  
          &:hover {
            color: var(--white);
            background-color: var(--color-orange);
            border-radius: 10px;
          }
  
          .country-flag {
            min-width: 21px;
            min-height: 15px;
            background-image: url('../../../../shared/assets/images/auth/flags.png');
            background-repeat: no-repeat;
            border: 1px solid black;
            box-sizing: border-box;
          }
  
          p {
            margin-left: 8px;
            font-size: 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }

  &_password {
    p {
      &.quality_1 {
        color: var(--color-red);
      }

      &.quality_2 {
        color: var(--color-orange);
      }

      &.quality_3 {
        color: var(--color-main);
      }
    }

    button {
      top: 15px;
      right: 20px;
    }

    svg {
      cursor: pointer;

      &.eye-opened {
        max-height: 18px;
      }

      &.eye-closed {
        position: relative;
        bottom: 3.5px;
        max-height: 24px;
      }

      &:hover {
        path {
          stroke: var(--color-white);
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  33% {
    transform: rotateZ(20deg);
  }

  66% {
    transform: rotateZ(-20deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}

.error {
  border-color: var(--color-red) !important;
}

