.tableContainer {
  width: 100%;
  height: 100%;
  border: 1px solid var(--trade-table-border-color);
  background: var(--aside-bg-color);
  border-radius: 16px;
  padding: 0 0 0 20px;
  max-height: 100%;
  overflow: auto;

  &.adaptive {
    border: none;
    padding: 0;
    border-radius: 0;
    background: transparent !important;
  }
}

.tableWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;

  .emptyTable {
    height: 100%;
  
    & > div {
      height: 100%;
    }
  }

  .loadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 52px);
  }
}

.referralsTable {
  table-layout: fixed;
  width: 100%;

  .thead {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    border-bottom: 1px solid var(--input-border-color);
    background: var(--aside-bg-color);
      
    th {
      width: max-content;
      white-space: pre-wrap;
      text-align: left;
      vertical-align: middle;
      height: 50px;
          
      .thWrapper {
        display: flex;
        align-items: center;
        padding: 10px 0;
        gap: 5px;
        width: 100%;
        margin-right: 5px;
        color: #5A5A5A;
        font: 12px "Inter-Regular", Arial, sans-serif;
      
        .thWrapperBody {
          display: flex;
          align-items: center;
          gap: 5px;
          width: 100%;
        }
      }
      
      &:first-child {
        width: 50px;
      }
    }
      
    td {
      width: 100%;
      text-align: left;
      vertical-align: middle;
      
      &:first-child {
        width: 50px;
      }
    }
  }
    
  .tbody {
    tr {
      transition: background-color 0.2s ease;
      
      .tdWrapper {
        .symbol {
          font: 12px "Inter-Regular", Arial, sans-serif;
        }

        img {width: 20px;}
      }
          
      td {
        padding: 15px 15px 15px 0; 
        box-sizing: border-box;
        vertical-align: middle;
      
        div {
          font: 12px "Inter-Regular", Arial, sans-serif;
          white-space: nowrap;
        }
      }
      
      &:not(:last-child) {
        border-bottom: 1px solid var(--input-border-color);
      }
    }
  }
}

html[data-theme='light'] {
  .tableWrapper {
    @media (width > 976px) {
      background: var(--white);
    }
  }
}